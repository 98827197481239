

import React, {useState} from 'react'
import { Container, Title, TitleRow, Row, Subtitle, SubtitleRow, Content, Values, RowContent, Tabs} from './styles';
import { ArrowUp, ArrowDown } from '../../assets/icons';
import moment from 'moment';
import { formatMoney, formatNumber, getRandomColor,adjustCloseToZero } from '../../services/functions';
import Table from '../TableTrip';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip, Legend} from 'recharts';
import ModalVisualizeOrder from './ModalVisualizeOrder';
import ModalVisualizeOrderSalary from './ModalVisualizeOrderSalary';

const columns = [
    {
        name: 'Produto',
        key: 'name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Comprador',
        key: 'providers_name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Vendido (unid.)',
        key: 'qty',
        type: 'float',
        unit: '',
    },
    {
        name: 'Executado',
        key: 'qty_exec',
        type: 'float',
        unit: '',
    },
    {
        name: 'Pendente',
        key: 'qty_pend',
        type: 'float',
        unit: '',
    },
    // {
    //     name: 'Comprado (%)',
    //     key: 'percent',
    //     type: 'float',
    //     unit: '%',
    // },
    {
        name: 'Vendido (R$)',
        key: 'total_value',
        type: 'money',
        unit: '',
    },
    {
        name: 'Executado',
        key: 'total_value_exec',
        type: 'money',
        unit: '',
    },
    {
        name: 'Pendente',
        key: 'total_value_pend',
        type: 'money',
        unit: '',
    },
    {
        name: 'Preço Médio',
        key: 'average_price',
        type: 'money',
        unit: '/unid.',
    },
]

const columnsOutputs = [
    {
        name: 'Insumo',
        key: 'name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Fornecedor',
        key: 'providers_name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Comprado (unid.)',
        key: 'qty',
        type: 'float',
        unit: '',
    },
    {
        name: 'Executado',
        key: 'qty_exec',
        type: 'float',
        unit: '',
    },
    {
        name: 'Pendente',
        key: 'qty_pend',
        type: 'float',
        unit: '',
    },
    // {
    //     name: 'Comprado (%)',
    //     key: 'percent',
    //     type: 'float',
    //     unit: '%',
    // },
    {
        name: 'Comprado (R$)',
        key: 'total_value',
        type: 'money',
        unit: '',
    },
    {
        name: 'Executado',
        key: 'total_value_exec',
        type: 'money',
        unit: '',
    },
    {
        name: 'Pendente',
        key: 'total_value_pend',
        type: 'money',
        unit: '',
    },
    {
        name: 'Preço Médio',
        key: 'average_price',
        type: 'money',
        unit: '/unid.',
    },
]

const columnsSalarys = [
    {
        name: 'Funcionário',
        key: 'name',
        type: 'string',
        unit: '',
    },
    {
        name: 'Valor (R$)',
        key: 'total_value',
        type: 'money',
        unit: '',
    },
    {
        name: 'Executado',
        key: 'total_value_exec',
        type: 'money',
        unit: '',
    },
    {
        name: 'Pendente',
        key: 'total_value_pend',
        type: 'money',
        unit: '',
    },
]

const tabsStates = [
    {
      id: 'inputs',
      label:'Entradas',
    },
    {
      id: 'outputs',
      label:'Saídas',
    },
    {
        id: 'salarys',
        label:'Remuneração',
      },
  
  ]

export default function Trip({ trip }) {
const [expanded, setExpanded] = useState(false);
const [tab, setTab] = useState(tabsStates[0].id);
const [modalVisualizeOrder, setModalVisualizeOrder] = useState(false);
const [selectedOrder, setSelectedOrder] = useState('');
const icon = expanded ? ArrowUp : ArrowDown;
const resultExec = parseFloat(trip?.total_exec_credit) - parseFloat(trip?.total_exec_pay);
const resultPend =  parseFloat(trip?.total_pend_credit) - parseFloat(trip?.total_pend_pay);

const colors = ['#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e', '#f1c40f', '#e67e22', '#e74c3c', '#95a5a6'];
let products = [];
let supplies = [];
let salarys = [];
let itensToShow = [...trip.products];
let salarysToShow = trip.salarys ? [...trip.salarys] : [];

itensToShow && itensToShow.map((product, index)=>{
    if(product.type === 'Entrada'){
        let color = index < 9 ? colors[index] : getRandomColor();
        let percent = product?.total_value_exec*100/trip?.totalInflow;
        let average_price = (product?.total_value_exec + product?.total_value_pend)/(product?.qty_exec + product?.qty_pend);
        let average_price_exec = product?.total_value_exec/product?.qty_exec;
        let average_price_pend = product?.total_value_pend/product?.qty_pend;
        let qty = product?.qty_exec + product?.qty_pend;
        let total_value = product?.total_value_exec + product?.total_value_pend;
        let total_value_pend  = adjustCloseToZero(product?.total_value_pend);
        let total_value_exec  = adjustCloseToZero(product?.total_value_exec);

        products.push({
            ...product, percent, color, average_price, average_price_pend, average_price_exec, qty, total_value,total_value_pend,total_value_exec,
        });
    } else {
        let color = index < 9 ? colors[index] : getRandomColor();
        let percent = product?.total_value_exec*100/trip?.totalOutflow;
        let average_price = (product?.total_value_exec + product?.total_value_pend)/(product?.qty_exec + product?.qty_pend);
        let qty = product?.qty_exec + product?.qty_pend;
        let total_value = product?.total_value_exec + product?.total_value_pend;
        let total_value_pend  = adjustCloseToZero(product?.total_value_pend);
        let total_value_exec  = adjustCloseToZero(product?.total_value_exec);
        supplies.push({
            ...product, percent, color, average_price, qty, total_value, total_value_pend, total_value_exec
        });
    }
});

salarysToShow && salarysToShow.map((product, index)=>{
        let color = index < 9 ? colors[index] : getRandomColor();
        let percent = product?.total_value_exec*100/trip?.totalOutflow;
        let average_price = (product?.total_value_exec + product?.total_value_pend)/(product?.qty_exec + product?.qty_pend);
        let qty = product?.qty_exec + product?.qty_pend;
        let total_value = product?.total_value_exec + product?.total_value_pend;
        let total_value_pend  = adjustCloseToZero(product?.total_value_pend);
        let total_value_exec  = adjustCloseToZero(product?.total_value_exec);
        salarys.push({
            ...product, percent, color, average_price, qty, total_value, total_value_pend, total_value_exec
        });
});

// let graphData = tab === 'inputs' ? products : supplies;

let graphData = [];

switch(tab){
    case 'inputs':
        graphData = products;
        break;
    case 'outputs':
        graphData = supplies;
        break;
    default:
        graphData = salarys;
}


function renderGraph(){
    return (
        <div style={{height: '100%', width:'auto', display:'flex', alignItems:'flex-start', justifyContent:'center',}}>
        <PieChart width={400} height={200}>
          <Pie
            data={graphData}
            dataKey="total_value"
            outerRadius={70}
            innerRadius={40}
            fill="#8884d8"
          >
              {graphData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip />
                <Legend 
                    layout="vertical" 
                    align="right" 
                    verticalAlign='middle'
                    iconSize={16}
                    wrapperStyle={{paddingLeft:16, fontSize: 12, maxHeight: 200, maxWidth:200, overflow: 'auto'}}
                    ></Legend>
        </PieChart>
        </div>
      );
}

    function renderTable(){
        switch(tab){
            case 'inputs':
                    return (
                        <Table 
                        columns={columns} 
                        rows={products || []} 
                        hasEditing={false} 
                        hasRemoving={false}
                        setSelectedItem={()=>{}} 
                        height="100%"
                        actionsInFirstLine={false}
                        fitWidth={false}
                        selectedOrder={selectedOrder}
                        setSelectedOrder={setSelectedOrder}
                        setModalVisualizeOrder={setModalVisualizeOrder}
                    ></Table>
                    );
            case 'outputs':
                return (
                    <Table 
                    columns={columnsOutputs} 
                    rows={supplies || []} 
                    hasEditing={false} 
                    hasRemoving={false}
                    setSelectedItem={()=>{}} 
                    height="100%"
                    actionsInFirstLine={false}
                    fitWidth={false}
                    selectedOrder={selectedOrder}
                    setSelectedOrder={setSelectedOrder}
                    setModalVisualizeOrder={setModalVisualizeOrder}
                ></Table>
                );
            default:
                return (
                    <Table 
                    columns={columnsSalarys} 
                    rows={salarys || []} 
                    hasEditing={false} 
                    hasRemoving={false}
                    setSelectedItem={()=>{}} 
                    height="100%"
                    actionsInFirstLine={false}
                    fitWidth={false}
                    selectedOrder={selectedOrder}
                    setSelectedOrder={setSelectedOrder}
                    setModalVisualizeOrder={setModalVisualizeOrder}
                ></Table>
                )
        }
    }


    return (
        <Container style={{height: 'auto', justifyContent:expanded ? 'flex-start' : 'center' }}>
            <Row>
            <TitleRow>
                    <img src={icon} alt={'Expandir'} style={{cursor:'pointer'}} onClick={()=> setExpanded(!expanded)}></img>
                    <Title>{trip?.boats_name}</Title>
            </TitleRow>
            <SubtitleRow>
                <Subtitle>Saída: {moment(trip?.begin_date).format('DD/MM/YYYY')}</Subtitle>
                <Subtitle>Chegada: {trip?.days?.days ? moment(trip?.end_date).format('DD/MM/YYYY') : 'Em aberto'}</Subtitle>
                <Subtitle >Saldo Executado: {formatMoney(resultExec)}</Subtitle>
                <Subtitle >Saldo Pendente: {formatMoney(resultPend)}</Subtitle>
                <Subtitle style={{color: 'var(--txt-title)', fontWeight: 'bold'}} >Saldo Total: {formatMoney(resultPend + resultExec)}</Subtitle>
            </SubtitleRow>
            </Row>
            <Row style={{display: expanded? 'flex': 'none', marginTop: 0, width: '100%', height: '100%'}}>
                <div style={{display:'flex', flexDirection: 'column', width:'100%'}}>
                    <RowContent style={{marginTop: 16, justifyContent:'flex-start'}}>
                        <Content>
                            <Values>Produção total: {formatNumber(trip?.production)} kg</Values>
                            <Values>Venda total: {formatNumber(parseFloat(trip?.totalSale))} kg</Values>
                            <Values>Desperdício:  {formatNumber(parseFloat(trip?.waste))} %</Values>
                            <Values>Consumo de óleo:  {formatNumber(parseFloat(trip?.oil_consumption || 0))} L</Values>
                        </Content>
                        <Content>
                            <Values>Quantidade de dias: {trip?.days?.days}</Values>
                            <Values>Espécie Alvo: {trip?.fishingtypes_name}</Values>
                            <Values>Preço Médio: {formatMoney(trip?.average_price)}/kg</Values>
                            <Values>Consumo de óleo/dia:  {formatNumber(parseFloat(trip?.daily_oil_consumption || 0))} L</Values>
                        </Content>
                        <Content >
                            <Values>Entradas: {formatMoney(trip?.totalInflow)}</Values>
                            <Values >Executado: <h1 style={{color:"var(--green)"}}>{formatMoney(trip?.total_exec_credit)}</h1></Values>
                            <Values>Pendente: <h1 style={{color:"#65bc85"}}>{formatMoney(trip?.total_pend_credit)}</h1></Values>
                        </Content>
                        <Content>
                            <Values>Saídas: {formatMoney(trip?.totalOutflow)}</Values>
                            <Values >Executado: <h1 style={{color:"var(--red)"}}>{formatMoney(trip?.total_exec_pay)}</h1></Values>
                            <Values>Pendente: <h1 style={{color:"#ff919c"}}>{formatMoney(trip?.total_pend_pay)}</h1></Values>
                        </Content>
                        <Content>
                            <Values>Saídas: {formatMoney(trip?.totalOutflow)}</Values>
                            <Values>Remuneração: {formatMoney(trip?.totalOutflowSalary)}</Values>
                            <Values>Pedidos: {formatMoney(trip?.totalOutflow - trip?.totalOutflowSalary)}</Values>
                        </Content>

                    </RowContent>
                    <Row>
                        <Tabs>
                            {
                                tabsStates.map((state, index) => {
                                if(state.id === tab){
                                    return (
                                    <h4 style={{color: '#256CE1', borderBottom: '1px solid #256CE1', fontWeight: 600
                                    }} onClick={()=> setTab(state.id)} key={index}>{state?.label}</h4>
                                    )
                                }
                                return (
                                    <h4 onClick={()=> setTab(state.id)} key={index}>{state?.label}</h4>
                                )
                                })
                            }
                        </Tabs>

                    </Row>  
                    <Row style={{marginTop: 8}}>
                      {renderTable()}
                    </Row>
                </div>
                {/* {renderGraph()} */}
            </Row>     
            {
            selectedOrder !== '' && modalVisualizeOrder && selectedOrder?.id_order && !selectedOrder.issalary && (
                <ModalVisualizeOrder
                open={selectedOrder !== '' && modalVisualizeOrder && selectedOrder?.id_order  && !selectedOrder.issalary} 
                setSelectedItem={setSelectedOrder} 
                selectedItem={selectedOrder}
                width={1100} 
                height={900} 
                ></ModalVisualizeOrder>
            )
            }
        {selectedOrder !== '' && selectedOrder.id_order && modalVisualizeOrder && selectedOrder.providers_name === null && (
          <ModalVisualizeOrderSalary
          open={selectedOrder !== '' && selectedOrder.id_order && selectedOrder.providers_name === null } 
          setSelectedItem={setSelectedOrder} 
          selectedItem={selectedOrder}
          width={1100} 
          height={900} 
        ></ModalVisualizeOrderSalary>
        )}
        </Container>
    )
}