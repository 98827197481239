import styled from "styled-components";

export const Container = styled.div`
  background: var(--background);
  height: 100vh;
  width: 100%;
  padding: 32px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #989898 #ffffff;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #989898;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ffffff;
  }
`;

export const Content = styled.div`
  height: auto;
  width: 100%;
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
`;
export const ContentLoading = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Row = styled.div`
width: 100%;
// display: flex;
// flex-direction: row;
display: grid;
grid-template-columns 3fr 2fr 2fr 1fr 1fr 1fr 1fr 1fr;
align-items: center;
justify-content: space-between;
`;
export const RowContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.8rem;
  // padding-bottom: 1rem;
  overflow-x: auto;
  scrollbar-height: thin;
  scrollbar-color: #989898 #ffffff;
  &::-webkit-scrollbar {
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #989898;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ffffff;
  }
`;

export const ValuesRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 160%;
  color: var(--txt-title);
  text-align: left;
`;

export const SectionTitle = styled.h1`
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 160%;
  color: var(--txt-title);
  text-align: left;
  border-bottom: 0.5px solid #b8b9c7;
`;

export const Column = styled.div`
  // width: 100%;
  display: flex;
  flex-direction: column;
`;

export const RowWeeks = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonWeek = styled.img`
  cursor: pointer;
  transition: transform 0.2s;
  transition: width 0.5s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;
