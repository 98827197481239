import React, {useState, useContext, useEffect} from 'react'
import Header from '../../components/Header';
import {
   Container,Content,Row, Title, RowContent,RowPagination,Tabs
  } from './styles';
import Button from "../../components/Button";
import Drawer from "../../components/Drawer";
import PaginationComponent from '../../components/Pagination';
import moment from 'moment';
import AppContext from "../../state/App.context";
import Autocomplete from "../../components/Autocomplete";
import TableStock from '../../components/TableStock';
import Table from '../../components/Table';
import { UseStock } from '../../hooks/useStock';
import { UseSubsidiaries } from '../../hooks/useSubsidiaries';
import { UseProviders } from '../../hooks/useProviders';
import { UseProducts } from '../../hooks/useProducts';
import { UseCostCenters } from "../../hooks/useCostCenters";
import { UseTrips} from "../../hooks/useTrips";
import { UseBoats} from "../../hooks/useBoats";
import LocalStorageService from '../../services/storage';
import ModalTakeFromStock from './ModalTakeFromStock';
import ModalReturnToStock from './ModalReturnToStock';
import ModalVisualizeOrder from './ModalVisualizeOrder';

const columns = [
  {
    name: 'Item',
    key:'item_name',
    type: 'string', 
    unit:'',
  },
  {
    name: 'Quantidade',
    key:'item_amount',
    type: 'number', 
    unit:'',
  },
  {
    name: 'Fornecedor',
    key:'providers_name',
    type: 'string', 
    unit:'',
  },
  {
    name: 'Subsidiária',
    key:'subsidiary_name',
    type: 'string', 
    unit:'',
  },
  {
    name: 'Data de criação',
    key:'created_at',
    type: 'date',  
    unit:'',
  },
]

const columnsOutputs = [
  {
    name: 'Item',
    key:'product_name',
    type: 'string', 
    unit:'',
  },
  {
    name: 'Quantidade',
    key:'output_amount',
    type: 'number', 
    unit:'',
  },
  {
    name: 'Total',
    key:'output_value',
    type: 'money', 
    unit:'',
  },
  {
    name: '% do total do pedido',
    key:'percent_total_order_value',
    type: 'percent', 
    unit:'%',
  },
  {
    name: 'Centro de Custo',
    key:'costs_center_name',
    type: 'string', 
    unit:'',
  },
  {
    name: 'Barco',
    key:'boats_name',
    type: 'string', 
    unit:'',
  },
  {
    name: 'Viagem',
    key:'trips_name',
    type: 'string', 
    unit:'',
  },
  {
    name: 'Safra',
    key:'harvest',
    type: 'string', 
    unit:'',
  },
  {
    name: 'Feito por',
    key:'created_by',
    type: 'string', 
    unit:'',
  },
  {
    name: 'Criado em',
    key:'created_at',
    type: 'date', 
    unit:'',
  },
  {
    name: 'Receptor',
    key:'employee_name',
    type: 'string', 
    unit:'',
  },
  {
    name: 'Observações',
    key:'notes',
    type: 'string', 
    unit:'',
  },
]


const columnsLogs = [
  {
    name: 'Ação',
    key:'user_action',
    type: 'string', 
    unit:'',
  },
  {
    name: 'Usuário',
    key:'e_mail',
    type: 'string', 
    unit:'',
  },
  {
    name: 'Observações',
    key:'notes',
    type: 'string', 
    unit:'',
  },
  {
    name: 'Data de criação',
    key:'created_at',
    type: 'date', 
    unit:'',
  },
]


const defaultFilters = {
  product: {id:0, label:'Todos'}, 
  provider: {id:0, label:'Todos'},
  subsidiary: {id:0, label:'Todas'},
  pages: 1, 
  limit: 10,
}
const defaultFiltersOutputs = {
  product: {id:0, label:'Todos'}, 
  costcenter: {id:0, label:'Todos'},
  boat: {id:0, label:'Todos'},
  trip: {id:0, label:'Todas'},
  pages: 1, 
  limit: 10,
}

const tabsStates = [
  {
    id: 'current_stock',
    label:'Estoque atual',
  },
  {
    id: 'outputs_stock',
    label:'Movimentações no estoque',
  },
  {
    id: 'logs_stock',
    label:'Histórico',
  },

]

function Stock(){
  const localStorageService = LocalStorageService();
  const { getFilteredStockItems, getOutputsStock, getStockHistory} = UseStock();
  const { subsidiaries } = UseSubsidiaries();
  const {products} = UseProducts();
  const {providers} = UseProviders();
  const { costCenters } = UseCostCenters();
  const { trips } = UseTrips();
  const { boats } = UseBoats();
  const [itensToShow, setItensToShow] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [, setSnack] = useContext(AppContext).snackState;
  const [filters, setFilters] = useState({...defaultFilters});
  const [filtersOutputs, setFiltersOutputs] = useState({...defaultFiltersOutputs});
  const [totalPages, setTotalPages] = useState(1);
  const [totalPagesOutputs, setTotalPagesOutputs] = useState(1);
  const [openModalTakeFromStock, setOpenModalTakeFromStock] = useState(false);
  const [openModalReturnToStock, setOpenModalReturnToStock] = useState(false);
  const [openModalVisualizeOrder, setOpenModalVisualizeOrder] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [tab, setTab] = useState(tabsStates[0].id);
  const [outputsToShow, setOutputsToShow] = useState([]);
  const [logsToShow, setLogsToShow] = useState([]);

  let productsOptions = [];
  products && !products.error && products.map((element)=> {
    if(element.isactive) {
      productsOptions.push({
        id: element?.id_product,
        label: element?.product_name,
      })
    }
  }
  )

  let subsidiariesOptions = [];
  subsidiaries && !subsidiaries.error && subsidiaries?.map((element)=> {
    if(element.isactive) {
      subsidiariesOptions.push({
        id: element?.id_subsidiary,
        label: element?.subsidiary_name,
      })
    }
  }
  )

  let providersOptions = [];
  providers && !providers.error && providers?.map((element)=> {
    if(element.isactive) {
      providersOptions.push({
        id: element?.id_providers,
        label: element?.providers_name,
      })
    }
  }
  )

  let costCentersOptions = [];
  costCenters && !costCenters.error && costCenters?.map((element)=> {
    if(element.isactive) {
      costCentersOptions.push({
      id: element?.costs_center_name,
      label: element?.costs_center_name,
      })
    }
});

let tripsOptions = [];
  
trips && !trips.error && trips?.map((trip)=> {
  const index = tripsOptions.findIndex((option) => option.label === trip.name);
  if(index === -1 && trip.isactive && tripsOptions.findIndex((element) => element.id === trip.trips_name) === -1){
    tripsOptions.push({
      id: trip?.trips_name,
      label: trip?.trips_name,
    })
  }
})

let boatsOptions = [];
boats && !boats.error && boats?.map((element)=> {
  if(element.isactive) {
    boatsOptions.push(
      {
        id: element?.boats_name,
        label: element?.boats_name,
      }
    )
  }
})

  useEffect(()=>{
    if(itensToShow.error){
      setSnack({
        open: true,
        severity: 'error', 
        message:itensToShow.error,
      })
    }
  },[itensToShow]);

  async function getInfo(){
    try{
      let formattedFilters = {
        item_name: filters?.product?.id ?  "'" + filters?.product?.label + "'" : "''",
        provider_name: filters?.provider?.id ?  "'" + filters?.provider?.label + "'" : "''",
        subsidiary_name: filters?.subsidiary?.id ?  "'" + filters?.subsidiary?.label + "'" : "''",
        pages: filters?.pages,
        limit: filters?.limit,
      }
      let stockResponse = await getFilteredStockItems(formattedFilters);
      if(stockResponse.success){
        let stockCopy = [...stockResponse?.data?.data];
        setItensToShow(stockCopy);
        setTotalPages(stockResponse?.data?.pages)
      } else {
        setSnack({
          open: true,
          severity: 'error', 
          message: stockResponse.message,
        });
      }
    } catch(err){
      console.log(err);
    }
  }

  useEffect(()=>{
    getInfo()
  },[ filters])

  useEffect(()=>{
    if(tab === 'current_stock'  && !openModalTakeFromStock) getInfo()
  },[ tab, openModalTakeFromStock])

  async function getOutputsInfo(){
    try{
      let formattedFilters = {
        product: filtersOutputs?.product?.id ?  "'" + filtersOutputs?.product?.label + "'" : "''",
        costcenter: filtersOutputs?.costcenter?.id ?  "'" + filtersOutputs?.costcenter?.label + "'" : "''",
        boat: filtersOutputs?.boat?.id ?  "'" + filtersOutputs?.boat?.label + "'" : "''",
        trip: filtersOutputs?.trip?.id ? filtersOutputs?.trip?.label  : "''",
        pages: filtersOutputs?.pages,
        limit: filtersOutputs?.limit,
      }
      let stockResponse = await getOutputsStock(formattedFilters);
      if(stockResponse.success){
        let stockCopy = [...stockResponse?.data?.data];
        setOutputsToShow(stockCopy);
        setTotalPagesOutputs(stockResponse?.data?.info[0]?.pages);
      } else {
        setSnack({
          open: true,
          severity: 'error', 
          message: stockResponse.message,
        });
      }
    } catch(err){
      console.log(err);
    }
  }

  useEffect(()=>{
    if(tab === 'outputs_stock' && !openModalReturnToStock) getOutputsInfo()
  },[tab, filtersOutputs, openModalReturnToStock])

  async function getHistoryInfo(){
    try{
      let logsResponse = await getStockHistory();
      if(logsResponse.success){
        setLogsToShow(logsResponse.data);
      } else {
        setSnack({
          open: true,
          severity: 'error', 
          message: logsResponse.message,
        });
      }
    } catch(err){
      console.log(err);
    }
  }

  useEffect(()=>{
    if(tab === 'logs_stock') getHistoryInfo()
  },[tab])


  const handleChangePage = (event, value) => {
    setFilters(
      {
        ... filters,
        pages: value,
      }
    )
  };
  const handleChangePageOutputs = (event, value) => {
    setFiltersOutputs(
      {
        ... filtersOutputs,
        pages: value,
      }
    )
  };

  
  function verifyFilterProps(obj) {
    let count = 0;
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop) && prop !== "limit" && prop !== "pages" && obj[prop].id !== 0) {
        count++;
      }
    }
    return count;
  }

  let activeFilters = verifyFilterProps(filters);
  let filterButtonLabel = activeFilters ? "Filtros(" + activeFilters + ")" : "Filtros";

  function cleanFilters(){
    setFilters({...defaultFilters});
  }
  function cleanFiltersOutputs(){
    setFiltersOutputs({...defaultFiltersOutputs});
  }
  function Filters(){
    return(
      <>
        <div style={{marginTop: 16, width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          <Button 
            label="Limpar filtros" 
            background="transparent" 
            color="#54577A" 
            borderColor="#54577A" 
            disabled={false} 
            onClick={()=> {cleanFilters()}}
            ></Button>
        </div>
        <div style={{marginTop: 16}}>
          <Autocomplete
            value={filters.product}
            width={200}
            disabled={false}
            options={[{id:0, label:'Todos'}, ...productsOptions]}
            fontSize={'0.7rem'} 
            label="Item"
            onChange={(value)=> {
              const copyFilters = {...filters};
              copyFilters.product = value;
              setFilters(copyFilters);
            }}
            ></Autocomplete>
        </div>
        <div style={{marginTop: 16}}>
          <Autocomplete
            value={filters.subsidiary}
            width={200}
            disabled={false}
            options={[{id:0, label:'Todas'}, ...subsidiariesOptions]}
            fontSize={'0.7rem'} 
            label="Subsidiária"
            onChange={(value)=> {
              const copyFilters = {...filters};
              copyFilters.subsidiary = value;
              setFilters(copyFilters);
            }}
            ></Autocomplete>
        </div>
        <div style={{marginTop: 16}}>
          <Autocomplete
              value={filters.provider}
              width={200}
              disabled={false}
              options={[{id:0, label:'Todos'}, ...providersOptions]}
              fontSize={'0.7rem'} 
              label="Fornecedor"
              onChange={(value)=> {
                const copyFilters = {...filters};
                copyFilters.provider = value;
                setFilters(copyFilters);
              }}
              ></Autocomplete>
        </div>
      </>

    )
  }

  function FiltersOutputs(){
    return(
      <>
        <div style={{marginTop: 16, width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          <Button 
            label="Limpar filtros" 
            background="transparent" 
            color="#54577A" 
            borderColor="#54577A" 
            disabled={false} 
            onClick={()=> {cleanFiltersOutputs()}}
            ></Button>
        </div>
        <div style={{marginTop: 16}}>
          <Autocomplete
            value={filtersOutputs.product}
            width={200}
            disabled={false}
            options={[{id:0, label:'Todos'}, ...productsOptions]}
            fontSize={'0.7rem'} 
            label="Item"
            onChange={(value)=> {
              const copyFilters = {...filtersOutputs};
              copyFilters.product = value;
              setFiltersOutputs(copyFilters);
            }}
            ></Autocomplete>
        </div>
        <div style={{marginTop: 16}}>
          <Autocomplete
            value={filtersOutputs.costcenter}
            width={200}
            disabled={false}
            options={[{id:0, label:'Todos'}, ...costCentersOptions]}
            fontSize={'0.7rem'} 
            label="Centro de Custo"
            onChange={(value)=> {
              const copyFilters = {...filtersOutputs};
              copyFilters.costcenter = value;
              setFiltersOutputs(copyFilters);
            }}
            ></Autocomplete>
        </div>
        <div style={{marginTop: 16}}>
          <Autocomplete
              value={filtersOutputs.boat}
              width={200}
              disabled={false}
              options={[{id:0, label:'Todos'}, ...boatsOptions]}
              fontSize={'0.7rem'} 
              label="Barco"
              onChange={(value)=> {
                const copyFilters = {...filtersOutputs};
                copyFilters.boat = value;
                setFiltersOutputs(copyFilters);
              }}
              ></Autocomplete>
        </div>
        <div style={{marginTop: 16}}>
          <Autocomplete
              value={filtersOutputs.trip}
              width={200}
              disabled={false}
              options={[{id:0, label:'Todas'}, ...tripsOptions]}
              fontSize={'0.7rem'} 
              label="Viagem"
              onChange={(value)=> {
                const copyFilters = {...filtersOutputs};
                copyFilters.trip = value;
                setFiltersOutputs(copyFilters);
              }}
              ></Autocomplete>
        </div>
      </>

    )
  }

  function renderFilters(){
    if(tab === 'outputs_stock'){
      return (
        <FiltersOutputs></FiltersOutputs>
      )
  } 
    return (
      <Filters></Filters>
    )
  }

  function renderTable(){
    if(tab === 'outputs_stock'){
      return (
        <>
        <TableStock 
          columns={columnsOutputs} 
          rows={outputsToShow || []} 
          setOpenModalReturnToStock={setOpenModalReturnToStock}
          setOpenModalVisualizeOrder={setOpenModalVisualizeOrder}
          setSelectedItem={setSelectedItem} 
          type='outputs'
          height='600px'
        ></TableStock>
      <RowPagination>
          <PaginationComponent totalPages={totalPagesOutputs} page={filtersOutputs?.pages} handleChangePage={handleChangePageOutputs} />
        </RowPagination>
      </>

      )
    } 
    if(tab === 'logs_stock'){
      return (
        <>
        <Table
          columns={columnsLogs} 
          rows={logsToShow || []} 
          setSelectedItem={()=>{}} 
          height='600px'
        ></Table>
      </>

      )
    }
    return (
            <>
            <TableStock 
              columns={columns} 
              rows={itensToShow || []} 
              setOpenModalTakeFromStock={setOpenModalTakeFromStock}
              setOpenModalVisualizeOrder={setOpenModalVisualizeOrder}
              setSelectedItem={setSelectedItem} 
              type='actual stock'
              height='600px'
            ></TableStock>
            <RowPagination>
              <PaginationComponent totalPages={totalPages} page={filters?.pages} handleChangePage={handleChangePage} />
            </RowPagination>
            </>
            )
  }

    return (
      <Container>
        <Header title="Estoque" subtitle="Gerencie os itens do estoque" />
        <Content>
        <Drawer open={openDrawer} handleOpen={setOpenDrawer} Children={renderFilters}></Drawer>

        <Row style={{marginBottom:8}}>
        <Tabs>
          {
            tabsStates.map((state, index) => {
              if(state.id === tab){
                return (
                  <h4 key={index} style={{color: '#256CE1', borderBottom: '2px solid #256CE1', fontWeight: 600
                }} onClick={()=> setTab(state.id)}>{state?.label}</h4>
                )
              }
              return (
                <h4 key={index}  onClick={()=> setTab(state.id)}>{state?.label}</h4>
              )
            })
          }
        </Tabs>
          <Button 
              label={filterButtonLabel}
              background="transparent" 
              color="#54577A" 
              borderColor="#54577A" 
              disabled={false} 
              onClick={()=> setOpenDrawer(true)}
          ></Button>
        </Row>
     {renderTable()}
        </Content>
        {openModalTakeFromStock && (
          <ModalTakeFromStock
          open={openModalTakeFromStock} 
          setOpenModalTakeFromStock={setOpenModalTakeFromStock}  
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem} 
          width={800} 
          height={"auto"} 
        ></ModalTakeFromStock>
        )}
          {openModalReturnToStock && (
            <ModalReturnToStock
            open={openModalReturnToStock} 
            setOpenModalReturnToStock={setOpenModalReturnToStock}  
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem} 
            width={600} 
            height={"auto"} 
          ></ModalReturnToStock>
        )}
        {openModalVisualizeOrder && (
            <ModalVisualizeOrder
            open={openModalVisualizeOrder} 
            setOpenModalVisualizeOrder={setOpenModalVisualizeOrder}
            setSelectedItem={setSelectedItem} 
            selectedItem={selectedItem}
            width={1100} 
            height={'auto'} 
          ></ModalVisualizeOrder>
        )}
      </Container>
    );
  };

  export default React.createElement(Stock);