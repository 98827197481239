import styled from 'styled-components';

export const Container = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    background: var(--white);
    border-radius: 10px;
    padding: 16px 30px;
    margin-top: 12px;
    ` ;

export const TitleRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    // height:100%;
` ;

export const Title = styled.h1`
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 180%;
    color: var(--txt-title);
    text-align: left;
    margin-left: 8px;
` ;

export const Subtitle = styled.h1`
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 180%;
    color: var(--txt-subtitle);
    text-align: left;
    margin-left: 8px;
` ;

export const Value = styled.h1`
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 180%;
    color: var(--blue);
    text-align: left;
` ;

export const Row = styled.div`
    width:100%;
    // height:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
` ;

export const RowContent = styled.div`
    width:100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 1rem;
` ;
export const Content = styled.div`
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    white-space: nowrap;
` ;

export const Values = styled.h1`
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 180%;
    color: var(--txt-subtitle);
    text-align: left; 
    margin-left: 8px;
    display: flex;
    flex-direction: row;
    h1{
        font-weight: 500;
        font-size: 0.8rem;
        margin-left: 0.2rem;
    }
` ;

export const SubtitleRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    // height:100%;
` ;

export const Tabs = styled.div`
width: 100%;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
align-items: center;
justify-content: center;
border-bottom: 0.2px solid rgba(84, 87, 122, 0.4);
h4{
    padding-top: 0.8rem;
    padding-bottom: 0.4rem;
    cursor: pointer;
    font-weight: 500;
    font-size: 0.8rem;
    color: var(--txt-subtitle);
    text-align: center;
}`;



