import React, {useState, useContext, useEffect} from 'react'
import moment from 'moment';
import Header from '../../components/Header';
import {
   Container,Content,Row, RowContent
  } from './styles';
import Drawer from "../../components/Drawer";
import Button from "../../components/Button";
import DynamicGraphs from "../../components/DynamicGraphs";
import ResultsBox from "../../components/ResultsBox";
import Searchbar from "../../components/Searchbar";
import Table from '../../components/Table';
import {UseTransactions} from '../../hooks/useTransactions';
import { UseCostCenters } from "../../hooks/useCostCenters";
import { UseCostCategories } from "../../hooks/useCostCategories";
import { UseSubsidiaries } from "../../hooks/useSubsidiaries";
import { UseProviders } from "../../hooks/useProviders";
import { UseProducts} from "../../hooks/useProducts";
import { UseTrips} from "../../hooks/useTrips";
import { UsePaymentTypes} from "../../hooks/usePaymentTypes";
import { UseBoats} from "../../hooks/useBoats";
import ModalAddTransaction
 from './ModalAddTransaction';
 import ModalEditTransaction
 from './ModalEditTransaction';
 import ModalVisualizeTransaction
 from './ModalVisualizeTransaction';
 import ModalRemoveTransaction
 from './ModalRemoveTransaction';
import ModalChooseColumns from './ModalChooseColumns';
import  api  from "../../services/api";
import Autocomplete from "../../components/Autocomplete";
import Datepicker from "../../components/Datepicker";
import AppContext from "../../state/App.context";
import Spinner from '../../components/Spinner';
import { UseUsers } from '../../hooks/useUsers';
import LocalStorageService from '../../services/storage';

const columns = [
  {
    name: 'Pedido',
    key:'id_order',
    type: 'string', 
    unit: '',
    show: true,
  },
  {
    name: 'Status',
    key:'last_order_status',
    type: 'number', 
    unit: '',
    show: true,
  },
  {
    name: 'Forn./Comp.',
    key:'provider',
    type: 'string', 
    unit: '',
    show: true,
  },
  {
    name: 'Prod./Ins.',
    key:'product',
    type: 'string', 
    unit: '',
    show: true,
  },
  {
    name: 'Qtd',
    key:'amount_value',
    type: 'number', 
    unit: '',
    show: true,
  },
  {
    name: 'Preço',
    key:'total_price',
    type: 'money', 
    unit: '',
    show: true,
  },
  {
    name: 'Desconto',
    key:'discount',
    type: 'money', 
    unit: '',
    show: true,
  },
  {
    name: 'Valor total',
    key:'total',
    type: 'money', 
    unit: '',
    show: true,
  },

  {
    name: 'Cent. Custo',
    key:'costCenter',
    type: 'string', 
    unit: '',
    show: true,
  },
  {
    name: 'Cat. de Custo',
    key:'category',
    type: 'string', 
    unit: '',
    show: true,
  },
  {
    name: 'Empresa',
    key:'subsidiary',
    type: 'string', 
    unit: '',
    show: true,
  },
  {
    name: 'Barco',
    key:'boat',
    type: 'string', 
    unit: '',
    show: true,
  },
  {
    name: 'Viagem',
    key:'trip',
    type: 'string', 
    unit: '',
    show: true,
  },
  {
    name: 'Tipo de pag.',
    key:'paymentType',
    type: 'number', 
    unit: '',
    show: true,
  },
  {
    name: 'Qtd. Parcelas',
    key:'payment_parcel',
    type: 'number', 
    unit: '',
    show: true,
  },
  {
    name: 'Lançamento',
    key:'on_date',
    type: 'date', 
    unit: '',
    show: true,
  },
  {
    name: 'Vencimento',
    key:'last_expiration_date',
    type: 'date', 
    unit: '',
    show: true,
  },
  {
    name: 'Pagamento',
    key:'last_payment_date',
    type: 'date', 
    unit: '',
    show: true,
  },
]

function Transactions(){
  const localStorageService = LocalStorageService();
  const { transactions, loading} = UseTransactions();
  const {getUserPreferences} = UseUsers();
  const [openDrawer, setOpenDrawer] = useState(false); 
  const [search, setSearch] = useState('');
  const [selectedTransaction, setSelectedTransaction] = useState({open: false, mode: '', name: '', id_transaction: null});
  const [openModalAddTransaction, setOpenModalAddTransaction] = useState(false);
  const [openModalChooseColumns, setOpenModalChooseColumns] = useState(false);
  const { costCenters } = UseCostCenters();
  const { costCategories } = UseCostCategories();
  const { providers } = UseProviders();
  const { products } = UseProducts();
  const { boats } = UseBoats();
  const { subsidiaries } = UseSubsidiaries();
  const { trips } = UseTrips();
  const { paymentTypes } = UsePaymentTypes();
  const [providersProducts, setProvidersProducts] = useState([]);
  const [transactionsToShow, setTransactionsToShow] = useState([]);
  const [columnsToShow, setColumnsToShow] = useState(columns);

  const [typeFilter, setTypeFilter] = useState({id: 'T', label:'Todos'});
  const [costCenterFilter, setCostCenterFilter] = useState({id: 'T', label:'Todos'});
  const [costCategoryFilter, setCostCategoryFilter] = useState({id: 'T', label:'Todas'});
  const [subsidiaryFilter, setSubsidiaryFilter] = useState({id: 'T', label:'Todas'});
  const [providerFilter, setProviderFilter] = useState({id: 'T', label:'Todos'});
  const [productFilter, setProductFilter] = useState({id: 'T', label:'Todos'});
  const [tripFilter, setTripFilter] = useState({id: 'T', label:'Todas'});
  const [paymentTypeFilter, setPaymentTypeFilter] = useState({id: 'T', label:'Todos'});
  const [startDateFilter, setStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');
  const [, setSnack] = useContext(AppContext).snackState;

  function cleanFilters(){
    const default_1 = {id: 'T', label:'Todos'};
    const default_2 = {id: 'T', label:'Todas'}
    setTypeFilter(default_1);
    setCostCenterFilter(default_1);
    setCostCategoryFilter(default_1);
    setSubsidiaryFilter(default_2);
    setProviderFilter(default_1);
    setProductFilter(default_1);
    setTripFilter(default_2);
    setPaymentTypeFilter(default_1);
    setStartDateFilter('');
    setEndDateFilter('');
  }

  useEffect(()=>{
    if(transactions.error){
      setSnack({
        open: true,
        severity: 'error', 
        message:transactions.error,
      })
    }
  },[transactions])


  let typeOptions = [
    {
      id: 'T', 
      label:'Todos'
    },
    {
      id: 0,
      label: 'Entrada',
    },
    {
      id: 1,
      label: 'Saída',
    }
  ]

  let costCentersOptions = [];
  costCenters && !costCenters.error && costCenters.map((element)=> {
    if(element.isactive) {
      costCentersOptions.push({
      id: element?.id_cost,
      label: element?.name,
      })
    }
});

let costCategoriesOptions = [];
costCategories &&  !costCategories.error && costCategories.map((element)=> {
  if(element.isactive) {
    costCategoriesOptions.push({
      id: element?.id_category,
      label: element?.name,
    })
  }
});

let subsidiariesOptions = [];
subsidiaries && !subsidiaries.error && subsidiaries.map((element)=> {
  if(element.isactive) {
    subsidiariesOptions.push({
      id: element?.id_subsidiary,
      label: element?.name,
    })
  }
 }
)

let providersOptions = [];
providers && !providers.error && providers.map((element)=> {
  if(element.isactive) {
    providersOptions.push(
      {
        id: element?.id_providers,
        label: element?.name,
      }
    )
  }
})

let productsOptions = [];

products && !products.error && products.map((element)=> {
  if(element.isactive) {
    productsOptions.push(
      {
        id: element?.id_product,
        label: element?.name,
      }
    )
  }
})

  let tripsOptions = [];
  
  trips && !trips.error && trips.map((trip)=> {
    const index = tripsOptions.findIndex((option) => option.label === trip.name);
    if(index === -1 && trip.isactive){
      tripsOptions.push({
        id: trip?.id_trip,
        label: trip?.name,
      })
    }
  })

  let paymentTypesOptions = [];
  paymentTypes && !paymentTypes.error && paymentTypes.map((element)=> {
    if(element.isactive) {
      paymentTypesOptions.push(
        {
          id: element?.id_paymenttype,
          label: element?.name,
        }
      )
    }
})

  function formattingTransactions(associations){
    try{
      let formattedTransactions = [];
      transactions && transactions.map((transaction) => {
        let costCenterIndex = costCenters.findIndex((costCenter) => costCenter.id_cost === transaction.fk_costs_center_id_cost);
        let costCenter = costCenterIndex !== -1 ? costCenters[costCenterIndex].name : 'Sem registros';
        
        let categoryIndex = costCategories.findIndex((category) => category.id_category === transaction.fk_costs_categories_id_category);
        let category = categoryIndex !== -1  ? costCategories[categoryIndex].name : 'Sem registros';

        let subsidiaryIndex = subsidiaries.findIndex((subsidiary) => subsidiary.id_subsidiary === transaction.fk_subsidiary_id_subsidiary);
        let subsidiary = subsidiaryIndex !== -1 ? subsidiaries[subsidiaryIndex].name : 'Sem registros';

        let paymentIndex = paymentTypes.findIndex((payment) => payment.id_paymenttype === transaction.fk_paymenttype_id_paymenttype);
        let paymentType = paymentIndex!==-1 ? paymentTypes[paymentIndex].name : 'Sem registros';

        let tripIndex = trips.findIndex((trip) => trip.id_trip === transaction.fk_trips_id_trip);
        let trip = tripIndex!== -1 ? trips[tripIndex].name : "Sem registros";

        let tripBoat = trips[tripIndex].fk_boats_id_boat;
        let boatIndex = boats.findIndex((boat) => boat.id_boat === tripBoat);
        let boat = boatIndex !== -1 ? boats[boatIndex].name : 'Sem registros';

        let receipt = transaction.receipt || '-';

        let associationIndex = associations.findIndex((association) => association.id === transaction.fk_providers_products_id);
        let providerId =  associations[associationIndex].fk_providers_id_providers;
        let productId = associations[associationIndex].fk_products_id_product;

        let providerIndex = providers.findIndex((provider) => provider.id_providers === providerId);
        let provider = providerIndex!==-1 ? providers[providerIndex].name : 'Sem registros';

        let productIndex = products.findIndex((product) => product.id_product === productId);
        let product = productIndex!==-1 ? products[productIndex].name : 'Sem registros';

        let last_order_status = transaction.order_status.includes('Pendente') ? 'Pendente' : 'Executada';

        let last_expiration_date = transaction.expiration_date[0] || '';

        let total = parseFloat(transaction.total_price) * parseFloat(transaction.amount_value, 10) - parseFloat(transaction.discount, 10);
        
        let amount_value = parseInt(transaction.amount_value, 10);

        transaction.order_status.map((status, index)=> {
          if(status === 'Pendente' && transaction.expiration_date[index] && moment(status).diff(moment(transaction.expiration_date[index])) < 0){
            last_expiration_date = transaction.expiration_date[index];
          }
        });

        let last_payment_date = '';
        transaction.payment_date.map((date, index)=> {
          if(date && transaction.order_status[index] === 'Executada'){
            last_payment_date = date;
          }
        });


        let formattedTransaction = {
          ... transaction,
          costCenter,
          category,
          subsidiary,
          paymentType,
          receipt,
          trip,
          boat,
          provider,
          product,
          last_order_status,
          last_expiration_date,
          last_payment_date,
          total,
          amount_value,
        }
        formattedTransactions.push(formattedTransaction);
      });
      return formattedTransactions;

    } catch(err){
      console.log(err);
    }
  }

  function filteringTransactions(result){
    try{
      let filteredTransactions = [...result];
      if(search){
        filteredTransactions = filteredTransactions.filter((transaction) => transaction.id_order.includes(search));
      }
      if(typeFilter.label && typeFilter?.id !== 'T'){
        filteredTransactions = filteredTransactions.filter((transaction) => transaction.type === typeFilter.label);
      };
      if(providerFilter.label && providerFilter?.id !== 'T'){
        filteredTransactions = filteredTransactions.filter((transaction) => transaction.provider === providerFilter.label);
      };
      if(productFilter.label && productFilter?.id !== 'T'){
        filteredTransactions = filteredTransactions.filter((transaction) => transaction.product === productFilter.label);
      };
      if(costCenterFilter.label && costCenterFilter?.id !== 'T'){
        filteredTransactions = filteredTransactions.filter((transaction) => transaction.costCenter === costCenterFilter.label);
      };
      if(costCategoryFilter.label && costCategoryFilter?.id !== 'T'){
        filteredTransactions = filteredTransactions.filter((transaction) => transaction.costCategory === costCategoryFilter.label);
      };
      if(subsidiaryFilter.label && subsidiaryFilter?.id !== 'T'){
        filteredTransactions = filteredTransactions.filter((transaction) => transaction.subsidiary === subsidiaryFilter.label);
      };
      if(tripFilter.label && tripFilter?.id !== 'T'){
        filteredTransactions = filteredTransactions.filter((transaction) => transaction.trip === tripFilter.label);
      };
      if(paymentTypeFilter.label && paymentTypeFilter?.id !== 'T'){
        filteredTransactions = filteredTransactions.filter((transaction) => transaction.paymentType === paymentTypeFilter.label);
      };
      if(startDateFilter !== '' && endDateFilter !== '' ){
        const startDate = moment(startDateFilter, 'YYYY-MM-DD');
        const endDate = moment(endDateFilter, 'YYYY-MM-DD');
        function verifyInterval(compareDate){
          return compareDate.isBetween(startDate, endDate, 'day', '[]');
        }
        function findMinDate(dates) {
          let minDate = moment(dates[0]);
          for (let i = 1; i < dates.length; i++) {
            const currentDate = moment(dates[i]);
            if (currentDate.isBefore(minDate)) {
              minDate = currentDate;
            }
          }
          return minDate;
        }
        // filteredTransactions = filteredTransactions.filter((transaction) => verifyInterval(moment(transaction.expiration_date, 'YYYY-MM-DD')));
        filteredTransactions = filteredTransactions.filter((transaction) => {
          let expiration_date = transaction?.expiration_date[0];
          if(transaction?.expiration_date.length > 1){
            expiration_date  = findMinDate(transaction?.expiration_date);
          }
          return verifyInterval(moment(expiration_date, 'YYYY-MM-DD')) 
        });

      };
      return filteredTransactions;
    } catch(err){
      console.log(err);
    }
  }

  function formatingColumns(preferences){
    try{
      let newColumns = [];
      columns.map((column)=>{
        let show = false;
        const index = preferences.findIndex((preference)=> preference.name === column.key);
        if(index !== -1){
          show = preferences[index].status === 'true';
        }
        newColumns.push(
          {
            ... column,
            show,
          }
        );
      });
      return newColumns;
    } catch(err){
      console.log(err)
    }
  }

  async function getInfo(){
    try{
      let response = await api.get('transactions/provider/product');
      let associations = response?.data;
      setProvidersProducts(associations);
      let result = formattingTransactions(associations);
      let filteredTransactions = filteringTransactions(result);
      setTransactionsToShow(filteredTransactions);
      const userInfo = localStorageService.getUserInfo();
      const id_user = userInfo?.id_user;
      if(id_user){
        const userPreferences = await getUserPreferences(id_user);
        if(userPreferences.success){
          const preferences = userPreferences?.data[0].column_preferences;
          const newColumns = formatingColumns(preferences);
          setColumnsToShow(newColumns);
        }
      }
    } catch(err){
      console.log(err);
    }
  }

  useEffect(()=>{
    if (!openModalAddTransaction) getInfo()
  },[transactions, typeFilter,costCenterFilter, costCategoryFilter, subsidiaryFilter, providerFilter, productFilter, tripFilter, paymentTypeFilter, startDateFilter, endDateFilter, search])

  
  function Filters(){
    return(
      <div style={{marginTop: 16}}>
        <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          <Button 
            label="Limpar filtros" 
            background="transparent" 
            color="#54577A" 
            borderColor="#54577A" 
            disabled={false} 
            onClick={()=> {cleanFilters()}}
            ></Button>
          </div>
          <Datepicker
            label="De"
            width={200}
            disabled={false}
            value={startDateFilter} 
            fontSize={'0.7rem'} 
            onChange={setStartDateFilter} />
          <Datepicker
          label="a"
          width={200}
          disabled={false}
          value={endDateFilter} 
          fontSize={'0.7rem'} 
          onChange={setEndDateFilter} />
         <br></br>   
         <Autocomplete
          value={typeFilter}
          width={200}
          disabled={false}
          options={typeOptions}
          fontSize={'0.7rem'} 
          label="Tipo"
          onChange={setTypeFilter}
          ></Autocomplete>   
          <br></br>   
         <Autocomplete
          value={providerFilter}
          width={200}
          disabled={false}
          options={[{id: 'T', label:'Todos'}, ...providersOptions]}
          fontSize={'0.7rem'}  
          label="Fornecedor/Comprador"
          onChange={setProviderFilter}
          ></Autocomplete>
          <br></br>
          <Autocomplete
          value={productFilter}
          width={200}
          disabled={false}
          options={[{id: 'T', label:'Todos'}, ...productsOptions]}
          fontSize={'0.7rem'}  
          label="Produto/Insumo"
          onChange={setProductFilter}
          ></Autocomplete>
          <br></br>
        <Autocomplete
          value={costCenterFilter}
          width={200}
          disabled={false}
          options={[{id: 'T', label:'Todos'}, ...costCentersOptions]}
          fontSize={'0.7rem'}  
          label="Centro de Custo"
          onChange={setCostCenterFilter}
          ></Autocomplete>
          <br></br>
        <Autocomplete
          value={costCategoryFilter}
          width={200}
          disabled={false}
          options={[{id: 'T', label:'Todos'}, ...costCategoriesOptions]}
          fontSize={'0.7rem'}  
          label="Categoria"
          onChange={setCostCategoryFilter}
          ></Autocomplete>
          <br></br>
        <Autocomplete
          value={subsidiaryFilter}
          width={200}
          disabled={false}
          options={[{id: 'T', label:'Todas'}, ...subsidiariesOptions]}
          fontSize={'0.7rem'}  
          label="Empresa"
          onChange={setSubsidiaryFilter}
        ></Autocomplete>
              <br></br>
        <Autocomplete
          value={tripFilter}
          width={200}
          disabled={false}
          options={[{id: 'T', label:'Todas'}, ...tripsOptions]}
          fontSize={'0.7rem'}  
          label="Viagem"
          onChange={setTripFilter}
        ></Autocomplete>
               <br></br>
        <Autocomplete
          value={paymentTypeFilter}
          width={200}
          disabled={false}
          options={[{id: 'T', label:'Todos'}, ...paymentTypesOptions]}
          fontSize={'0.7rem'}  
          label="Tipo de pagamento"
          onChange={setPaymentTypeFilter}
        ></Autocomplete>
      </div>
    )
  }

  function renderTable(){
    if(loading){
      return (
        <div style={{width:'100%', height:200}}>
        <Spinner width={40} fontSize={14}></Spinner>
        </div>
      )
    } else {
      return (
        <Table 
        columns={columnsToShow.filter(element => element.show)} 
        rows={transactionsToShow || []} 
        hasEditing={true} 
        hasRemoving={true}
        setSelectedItem={setSelectedTransaction} 
        colorfulRows={true}
        actionsInFirstLine={true}
      ></Table>
      )
    }
  }


    return (
      <Container>
        <Header title="Transações" subtitle="Visualize e gerencie as transações de todas as empresas" />
        <Content>
          <Drawer open={openDrawer} handleOpen={setOpenDrawer} Children={Filters}></Drawer>
          <Row>
          <Searchbar value={search} onChange={setSearch} disabled={false} width={300} placeholder="Buscar pedido..." fontSize={'0.7rem'} ></Searchbar>
            <Button
                label="Editar colunas" 
                background="#256CE1" 
                color="white" 
                borderColor="#256CE1" 
                disabled={false} 
                onClick={()=> setOpenModalChooseColumns(true)}
              ></Button>
            <Button
              label="Adicionar transação" 
              background="#256CE1" 
              color="white" 
              borderColor="#256CE1" 
              disabled={false} 
              onClick={()=> setOpenModalAddTransaction(true)}
             ></Button>
            <Button 
              label="Filtros" 
              background="transparent" 
              color="#54577A" 
              borderColor="#54577A" 
              disabled={false} 
              onClick={()=> setOpenDrawer(true)}
            ></Button>
          </Row>
          <RowContent>
            <DynamicGraphs title="Entradas e Saídas" transactions={transactionsToShow || []} loading={loading}></DynamicGraphs>
            <ResultsBox title="Resultado" transactions={transactionsToShow || []} loading={loading}></ResultsBox>
          </RowContent>
          <Row>
            <div style={{height: 16}}></div>
          </Row>
          <RowContent>
            {renderTable()}
          </RowContent>
        </Content>
        <ModalAddTransaction
         open={openModalAddTransaction} 
         handleOpen={setOpenModalAddTransaction} 
         width={1000} 
         height={900} 
         ></ModalAddTransaction>
           <ModalEditTransaction
          open={selectedTransaction.open && selectedTransaction.mode === 'edit'} 
          setSelectedItem={setSelectedTransaction} 
          selectedItem={selectedTransaction}
          width={1000} 
          height={900} 
        ></ModalEditTransaction>
        <ModalRemoveTransaction
          open={selectedTransaction.open && selectedTransaction.mode === 'remove'} 
          setSelectedItem={setSelectedTransaction} 
          selectedItem={selectedTransaction}
          width={600} 
          height={160} 
        ></ModalRemoveTransaction>
            <ModalVisualizeTransaction
          open={selectedTransaction.open && selectedTransaction.mode === 'visualize'} 
          setSelectedItem={setSelectedTransaction} 
          selectedItem={selectedTransaction}
          width={1000} 
          height={900} 
        ></ModalVisualizeTransaction>
           <ModalChooseColumns
          open={openModalChooseColumns} 
          handleOpen={setOpenModalChooseColumns} 
          width={600} 
          height={700} 
          columnsToShow={columnsToShow}
        ></ModalChooseColumns>
      </Container> 
    );
  };

  export default React.createElement(Transactions);