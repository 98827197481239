

import {useState, useContext, useEffect} from 'react';
import { Container, Title, Graph, RowSelect, TooltipBox} from './styles'
import { BarChart, XAxis, YAxis, Tooltip, Bar, Legend, ResponsiveContainer} from 'recharts'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Spinner from '../Spinner';
import { formatMoney } from '../../services/functions'; 
import {UseOrders} from '../../hooks/useOrders';
import AppContext from "../../state/App.context";
import moment from 'moment';

const categories = [
  {
    id: 'subsidiary_name',
    label: 'Por empresa',
    url: 'subsidiary'
  },
  // {
  //   id: 'boats_name',
  //   label: 'Por barco',
  //   url: 'boat'
  // },
  {
    id: 'costs_center_name',
    label: 'Por centro de custo',
    url: 'costscenter',
  },
  {
    id: 'paymenttype_name',
    label: 'Por tipo de pagamento',
    url: 'paymenttype'
  },
  {
    id: 'providers_name',
    label: 'Por fornecedor/comprador',
    url: 'providers',
  },
  // {
  //   id: 'trips_name',
  //   label: 'Por número da viagem',
  //   url: 'trips'
  // },
]

export default function DynamicGraphs({ title, filters }) {
  const {getResultByCategory} = UseOrders();
  const [loading, setLoading] = useState(false);
  const [graphData, setGraphData] = useState([])
  const [, setSnack] = useContext(AppContext).snackState;
  const [category, setCategory] = useState(categories[0]);

  function getHighestValue(objArray) {
    let highestValue = 0;

    objArray.forEach(obj => {
        if (obj.saida !== null) {
            const outputValue = parseFloat(obj.saida);
            if (outputValue > highestValue) {
                highestValue = outputValue;
            }
        }

        if (obj.entrada !== null) {
            const inputValue = parseFloat(obj.entrada);
            if (inputValue > highestValue) {
                highestValue = inputValue;
            }
        }
    });

    return highestValue;
}

let maxValue = graphData.length > 0 ? getHighestValue(graphData) : 0;

  async function getInfo(){
    try{
        setLoading(true);
        let startDate = filters?.startDate;
        let endDate = filters?.endDate;
        const response = await getResultByCategory(category?.url, startDate, endDate);
        if(response.success){
          setGraphData(response?.data);
        } else {
            setSnack({
                open: true,
                severity: 'error', 
                message: response.message,
              });
        }
    } catch(err){
        console.log(err)
    } finally{
        setLoading(false);
    }
}

useEffect(()=>{
   if(filters.startDate !== "''" && filters.endDate !== "''") getInfo();
}, [filters.startDate, filters.endDate, category])


    const Select = () => {
        return(
            <Autocomplete
            value={category}
            autoComplete
            autoSelect
            disableClearable
            disablePortal
            id="customized-autocomplete"
            options={categories}
            size="small"
            onChange={(event, value)=> {
                setCategory(value);
            }}
            sx={{ 
               marginTop: 0,
                width: 180, 
                background:'#FFFFFF', 
                borderRadius:'10px', 
                border: 'none',
                '& input': {
                          color: '#141522',
                          fontFamily:'Plus Jakarta Sans',
                          fontSize:'0.7rem'
                      }
             }}
            renderInput={(params) => <TextField {...params} variant="outlined" style={{color: '#141522'}} />}
            loadingText="Carregando..."
            noOptionsText="Nenhuma opção carregada"
        />
        )
    }

    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload) {
        let inflow = payload[0]?.payload?.entrada;
        let outflow = payload[0]?.payload?.saida;
        return (
          <TooltipBox>
            <p className="label">{`${label}`}</p>
            <p className="value" style={{color:'#03A03E'}}>Entradas: {inflow ? formatMoney(inflow) : 0}</p>
            <p className="value" style={{color:'#FF4D5E' }}>Saídas: {outflow ? formatMoney(outflow) : 0}</p>
          </TooltipBox>
        );
      }
      return null;
    };

    function renderGraph(){
      if(loading) {
        return (
          <Spinner width={40} fontSize={0}></Spinner>
        )
      } 
      if(filters.startDate === "''" || filters.endDate === "''"){
        return (
          <Graph>
            <div style={{height:150, width:'100%', display:'flex',justifyContent:'center'}}>
              <h1 style={{fontSize:14,textAlign: 'center',  paddingTop:'2rem', opacity:0.6}}>Selecione um intervalo de data nos filtros</h1>
          </div>
          </Graph>
        )
      }

        return (
            <Graph>
            <ResponsiveContainer width="100%" height={150} >
            <BarChart data={graphData}>
                <XAxis dataKey={category?.id} tickSize={12} tick={{fontSize:12}}/>
                <YAxis tick={{fontSize:12}} tickFormatter={(element)=> element/1000 + 'k'} domain={[0, maxValue]}/>
                <Tooltip content={<CustomTooltip />} />
                <Legend 
                    layout="vertical" 
                    align="right" 
                    verticalAlign='middle'
                    iconSize={16}
                    wrapperStyle={{paddingLeft:16, fontSize: 12}}
                
                    ></Legend>
                <Bar dataKey="entrada" fill="#03A03E" />
                <Bar dataKey="saida" fill="#FF4D5E" />
            </BarChart>
            </ResponsiveContainer>
            </Graph>
        )
    }


    return (
        <Container>
            <RowSelect>
            <Title>{title}</Title>
            <Select></Select>
            </RowSelect>
            {renderGraph()}
        </Container>
    )
}