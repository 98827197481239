import React, {useState, useContext, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import {
   Container,Content,Row, TripTitle, RowContent
  } from './styles';
import { UseTrips } from "../../hooks/useTrips"
import Button from "../../components/Button";
import Drawer from "../../components/Drawer";
import moment from 'moment';
import AppContext from "../../state/App.context";
import Trip from '../../components/Trip';
import BoatsGraph from '../../components/BoatsGraph';
import ResultsBoatsBox from '../../components/ResultsBoatsBox';
import { tripsOptions, harvestOptions } from './options';
import ValueBox from '../../components/ValueBox';
import { formatMoney } from '../../services/functions';
import TripsFilters from './Filters/index'


function TripsByBoat(){
  const { getTripsByBoats, getTripsOverview} = UseTrips();
  const [tripsToShow, setTripsToShow] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [, setSnack] = useContext(AppContext).snackState;
  const location = useLocation();
  const data = location?.state;
  const defaultFilters = {
    harvest: data?.harvest ? {label: data?.harvest, id: data?.harvest} : harvestOptions[1],
    trip:  data?.trip ? {label: data?.trip, id: data?.trip} : tripsOptions[0],
    boat: data?.boat ? {label: data?.boat, id: data?.boat} : {
      id: 'Todos',
      label: 'Todos',
    },
    fishingType: {
      id: 'Todas',
      label: 'Todas',
    },
    product: {
      id: 'Todos',
      label: 'Todos',
    },
    employee: {
      id: 'Todos',
      label: 'Todos',
    },
    costCategory: {
      id: 'Todas',
      label: 'Todas',
    },
    provider: {
      id: 'Todos',
      label: 'Todos',
    }
  }
  const [filters, setFilters] = useState({... defaultFilters});

  function groupingSameTrips(trips){
    try{
      let groupedTrips = [];
      trips.map((trip)=>{
        const tripIndex = groupedTrips.findIndex((groups)=> groups?.id_trip === trip?.id_trip);
        if(tripIndex === -1){
          if(trip.issalary){
            groupedTrips.push({
              ... trip,
              products: [],
              salarys: [...trip?.products],
            });
          } else {
            groupedTrips.push({...trip, salarys: []});
          }
        } else {
          let tripCopy = groupedTrips[tripIndex];
          let copyProducts = tripCopy?.products ?  [...tripCopy?.products] : [];
          let copySalarys = tripCopy?.salarys ?  [...tripCopy?.salarys] : [];
          let total_exec_pay = parseFloat(tripCopy?.total_exec_pay) + parseFloat(trip?.total_exec_pay);
          let total_pend_pay = parseFloat(tripCopy?.total_pend_pay) + parseFloat(trip?.total_pend_pay);
          let total_exec_credit = parseFloat(tripCopy?.total_exec_credit) + parseFloat(trip?.total_exec_credit);
          let total_pend_credit = parseFloat(tripCopy?.total_pend_credit) + parseFloat(trip?.total_pend_credit);
          let total_kg_cost = parseFloat(tripCopy?.kg_cost) + parseFloat(trip?.kg_cost);
          let total_average_price= parseFloat(tripCopy?.average_price) + parseFloat(trip?.average_price);
          let balance = parseFloat(tripCopy?.balance) + parseFloat(trip?.balance);
          let qty_exec_kg = parseFloat(tripCopy?.qty_exec_kg) + parseFloat(trip?.qty_exec_kg);
          let qty_pend_kg = parseFloat(tripCopy?.qty_pend_kg) + parseFloat(trip?.qty_pend_kg);
          if(trip.issalary){
            copySalarys.push(...trip?.products);
          } else {
            copyProducts.push(...trip?.products);
          }
          groupedTrips[tripIndex].products = copyProducts;
          groupedTrips[tripIndex].salarys = copySalarys;
          groupedTrips[tripIndex].total_exec_pay = total_exec_pay;
          groupedTrips[tripIndex].total_pend_pay = total_pend_pay;
          groupedTrips[tripIndex].total_exec_credit = total_exec_credit;
          groupedTrips[tripIndex].total_pend_credit = total_pend_credit;
          groupedTrips[tripIndex].total_kg_cost = total_kg_cost;
          groupedTrips[tripIndex].average_price = total_average_price;
          groupedTrips[tripIndex].balance = balance;
          groupedTrips[tripIndex].qty_exec_kg = qty_exec_kg;
          groupedTrips[tripIndex].qty_pend_kg = qty_pend_kg;
        }
      })
      return groupedTrips;
    } catch(err){
      console.error(err);
    }
  }

  function formatingTrips(trips) {
    try {
      const modifiedTrips = trips.map(trip => {
        const { begin_date, end_date, products,production, salarys, ...tripCopy } = trip;
        let status = 'Fechada';
        let totalInflow = parseFloat(trip?.total_exec_credit) + parseFloat(trip?.total_pend_credit);
        let totalOutflow = parseFloat(trip?.total_exec_pay) + parseFloat(trip?.total_pend_pay);
        let totalSale = parseFloat(trip?.qty_exec_kg) + parseFloat(trip?.qty_pend_kg);
        let waste = 0;
        if (moment(begin_date).isAfter(end_date)) {
          status = 'Aberta';
        }
        waste = production ? (production - totalSale)*100/production : 0;
        let totalOutflowSalary = 0;
        trip?.salarys.map((salary)=>{
          totalOutflowSalary += salary?.total_value_exec + salary?.total_value_pend;
        })
        return { ...tripCopy, status, waste, products,production, totalInflow, totalOutflow, totalSale, salarys, totalOutflowSalary, begin_date, end_date};
      });
      return modifiedTrips;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  let totalOutflowPend = 0;
  let totalInflowPend = 0;

  tripsToShow && tripsToShow.map((trip)=>{
      totalInflowPend = totalInflowPend + parseFloat(trip?.total_pend_credit);
      totalOutflowPend = totalOutflowPend + parseFloat(trip?.total_pend_pay);
  })

  async function getInfo(){
    try{
      let trips = [];
      let formattedFilters = {
        trips_name: filters?.trip?.label  === 'Todas' ?  "''" : filters?.trip?.label,
        boats_name: filters?.boat?.label === 'Todos' ?  "''" : filters?.boat?.label,
        harvest: filters?.harvest?.label === 'Todas' ?  "''" : filters?.harvest?.label,
        fishingtypes_name: filters?.fishingType?.label === 'Todas' ?  "''" : filters?.fishingType?.label,
        product_name: filters?.product?.label === 'Todos' ? "''" : filters?.product?.label,
        full_name: filters?.employee?.label === 'Todos' ? "''" : filters?.employee?.label,
        costs_categories_name: filters?.costCategory?.label === 'Todas' ?  "''" : filters?.costCategory?.label,
        providers_name: filters?.provider?.label === 'Todos' ? "''" : filters?.provider?.label,
      }
      let tripsResponse = await getTripsOverview(formattedFilters);
      if(tripsResponse.success){
        let groupedTrips = groupingSameTrips(tripsResponse?.data);
        trips = formatingTrips(groupedTrips);
        setTripsToShow(trips);
      } else {
      setSnack({
        open: true,
        severity: 'error', 
        message:tripsResponse.message,
      })
      }
    } catch(err){
      console.log(err);
    }
  }

  useEffect(()=>{
    getInfo();
  },[filters])

  function Filters(){
    return (
      <TripsFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters}></TripsFilters>
    )
  }


    return (
      <Container>
        <Header title="Viagens por barco" subtitle="Visualize as viagens realizadas de cada barco" />
        <Content>
        <Drawer open={openDrawer} handleOpen={setOpenDrawer} Children={Filters}></Drawer>
          <Row>
          <TripTitle>{filters?.trip?.id}º VG {filters?.harvest?.id}</TripTitle>
              <Button 
              label="Filtros" 
              background="transparent" 
              color="#54577A" 
              borderColor="#54577A" 
              disabled={false} 
              onClick={()=> setOpenDrawer(true)}
              ></Button>
          </Row>
          <Row style={{width:'100%'}}>
          <TripTitle>Barcos</TripTitle>
          </Row>
          <RowContent>
            <BoatsGraph  trips={tripsToShow || []} loading={false}></BoatsGraph>
            <ResultsBoatsBox trips={tripsToShow || []} loading={false}></ResultsBoatsBox>
            <div>            
            <ValueBox loading={false} value={formatMoney(totalOutflowPend)} title="Valor pendente a pagar" color="#FF4D5E"></ValueBox>
            <ValueBox loading={false} value={formatMoney(totalInflowPend)} title="Valor pendente a receber" color="#03A03E"></ValueBox>   
            </div>
          </RowContent>
          {tripsToShow && tripsToShow.map((trip, index)=>{
            return (
              <Trip trip={trip} key={index}></Trip>
            )
          })}
        </Content>
      </Container>
    );
  };

  export default React.createElement(TripsByBoat);