import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  padding: 20px;
  background: #f8f9fa;
  overflow-y: auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: 100%;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const TripTitle = styled.h2`
  font-size: 24px;
  color: #333;
`;

export const RowContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const TableContainer = styled.div`
  overflow-x: auto;
  margin-bottom: 20px;

  table {
    width: 100%;
    border-collapse: collapse;

    th, td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
    }

    th {
      background-color: #f2f2f2;
      color: #333;
    }

    tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    .entradas td {
      color: green;
      font-weight: bold;
    }

    .saidas td {
      color: red;
      font-weight: bold;
    }
  }
`;
